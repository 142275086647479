<template>
    <v-col id="PaineCardSituacaoUnidadesChart" sm="12" lg="12" class="fill-height col-painel pa-0" fluid>
      <v-card v-resize="onResize2" class="fill-height pb-4" elevation="1" :loading="loading">
        <v-app-bar flat color="rgba(0, 0, 0, 0)" >
          <v-toolbar-title class="text-h6 pl-0 cor_titulo_painel">
            Situação dos Imóveis
          </v-toolbar-title>
          <v-toolbar-items>
            <v-icon x-small class="vue-draggable-handle" color="#A0A0A0">mdi-arrow-all</v-icon>
          </v-toolbar-items>
        </v-app-bar>

      <v-card-text class="text-h6"></v-card-text>

        <div class="ml-3">
          <canvas id="situacao-chart" ></canvas>
        </div>
    </v-card>
    </v-col>
</template>

<script>
//import { Chart, LineController, LineElement, PointElement, LinearScale, Title } from 'chart.js/auto'
import { Chart, registerables } from 'chart.js';
import 'chartjs-adapter-moment'; // or another adapter to avoid moment
Chart.register(...registerables);
import situacaoChartData from '../../../Graficos/GraficosJs/situacao.js'
import { API } from "../../../../services/API";
import { formatNumber } from "../../../../services/funcoes";

export default {
  name: "PaineCardSituacaoUnidadesChart",

  props : {
    empreendimento_selecionado: {
      handler() {
        this.parseData();
      },
      default   : 1,
      deep      : true,
      immediate : true,
    },

  },

  data() {
    return {
      enabled               : null,
      loading               : false,
      slots                 : ["footer"],
      situacaoChartData     : situacaoChartData,
      formatNumber          : formatNumber,
    };
  },

  watch: {

    // Mudou o Empreendimento -----------------------------------
    empreendimento_selecionado: {
      handler(val, valOld) {
        this.parseData(val, valOld);
      },
      deep: true,
      immediate: true,
    }

  },

  methods: {

    onResize2 () {
      //console.log('---', document.getElementById("card2").offsetHeight)
    },

    parseData(val, valOld) {
      if (val != valOld) {
        this.loading = true;
        this.painel_SituacaoUnidadesChart(
          val,
          this.data_inadimplencia_inicial,
          this.data_inadimplencia_final
        );
      }
    },

    isEnabled(slot) {
      return this.enabled === slot;
    },

    async painel_SituacaoUnidadesChart(p_codEmpreendimento, p_data_inicial, p_data_final) {
      const datas = { cod_empreendimento: p_codEmpreendimento, data_inicial: p_data_inicial, data_final: p_data_final };
      const resp = await API.get(`painel_proprietario`, {
        params: datas,
      });
      var _this = this;
      var resp_data;

      // Montando Gráfico da Situação --------------------------------------------------------
      if (resp && resp.data.result.resumo_financeiro) {
        this.resumo_financeiro = resp.data.result.resumo_financeiro[0]
      }
      // // Montando Gráfico da Situação --------------------------------------------------------
      if (resp && resp.data.result.situacao) {
        resp_data = resp.data.result.situacao;
        this.situacaoChartData.data.labels = [];
        this.situacaoChartData.data.datasets[0].data = [];
        this.situacaoChartData.data.datasets[0].label = [];
        this.situacaoChartData.data.datasets[0].backgroundColor = [];
        this.situacaoChartData.labels = resp_data;
        resp_data.map(function (value) {
          _this.situacaoChartData.data.labels.push(value.situacao_nome + ' - ' + value.count);
          _this.situacaoChartData.data.datasets[0].data.push(value.count);
          _this.situacaoChartData.data.datasets[0].backgroundColor.push(value.situacao_venda_cor_html);
        });
        const ctx = document.getElementById("situacao-chart");
        const ctx_parent = ctx.parentElement;
        while (ctx_parent.firstChild) {
          ctx_parent.removeChild(ctx_parent.firstChild);
        }
        var canv = document.createElement("canvas");
        canv.setAttribute("id", "situacao-chart");
        canv.setAttribute("style", "opacity:0.8; background-color:#FFFFFF; position: relative; max-height:40vh; width:80vw");
        //max-height="300px" style="position: relative; height:40vh; width:80vw">
        ctx_parent.appendChild(canv);
        new Chart(canv, this.situacaoChartData);
        this.loading = false;        
      }

    },

  },
  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },

    itemByinject() {
      if (this.$item) {
        return this.$item();
      }
      return null;
    },

  },

};
</script>


<style scoped>

@media(min-width: 1441px) {
  .col-painel .min-height {
    /* height: 733px!important; */
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .canvas-height {
    height:300px;
  }

  .col-painel,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .col-painel,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.canvas-height {
  height:432px;
}

.content {
  height: 100%;
  /* max-height: 517px; */
  width: 100%;
  /* border: 1px solid #c2c2c2;
  border-radius: 5px; */
  background-color: #FFFFFF;
}

.cor_titulo_painel {
  font-size: 18px !important;
  color: #3268a3;
}

.vue-draggable-handle {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;
    padding: 1px 1px 1px 1px;
    background-origin: content-box;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: move;
}

</style>
