<template>
    <v-col id="PainelCardPrevisaoRecebimento" sm="12" lg="12" class="fill-height col-painel pa-0" fluid>

      <v-card class="fill-height" elevation="1" :loading="loading">

        <v-app-bar flat color="rgba(0, 0, 0, 0)" >
          <v-toolbar-title class="text-h6 pl-0 cor_titulo_painel">
            Previsão de Recebimento
          </v-toolbar-title>
          <v-toolbar-items>
            <v-icon x-small class="vue-draggable-handle" color="#A0A0A0">mdi-arrow-all</v-icon>
          </v-toolbar-items>
        </v-app-bar>

        <v-card-text class="date-mobile h6 pt-0 mb-0 pb-0">
        <table class="d-flex">
          <tr class="row-date pt-0 mt-n1">
            <td class="label-date-solo">
              <label class="mb-1 mr-4 h6 label-date">De </label>
            </td>
            <td class="td-mobile">
              <label class="mt-3 mr-6 h6 d-flex d-sm-none label-date">De </label>
              <v-text-field
                class="data-input"
                type="date"
                v-model="data_previsao_recebimento_inicial"
                dense
                :value="data_previsao_recebimento_inicial"
              ></v-text-field>
            </td>
            <td class="label-date-solo">
              <label class="mb-1 ml-4 mr-4 h6 label-date">Até</label>
            </td>
            <td class="td-mobile">
              <label class="mt-3 mr-5 h6 d-flex d-sm-none label-date">Até </label>

              <v-text-field
                class="data-input"
                type="date"
                v-model="data_previsao_recebimento_final"
                dense
                :value="data_previsao_recebimento_final"
              ></v-text-field>
            </td>
            <td class="label-date-solo ">
              <label class="mt-4 mb-4 ml-4 mr-4 label-date">Agrupado</label>
            </td>
            <td class="td-mobile">
              <label class="mt-3 mr-5 h6 d-flex d-sm-none label-date">Agrupado</label>
              <v-select
                :items="items_agrupado"
                v-model="previsao_recebimento_agrupado"
                item-text="nome"
                item-value="id"
                class="mb-0 data-input"
                dense
              ></v-select>
            </td>
          </tr>
        </table>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="previsao_recebimento"
        disable-pagination
        fixed-header
        :hide-default-header="isMobile"
        class="elevation-0 ml-4 mr-4"
        mobile-breakpoint="0"
        fixed-fooder
        hide-default-footer
        dense
        :style="{'height': (gridheight - 180) + 'px', 'margin-top':'-30px'}"
      >
        <template v-slot:body="{ items }">
          <tbody v-if="!isMobile" class="">
            <tr v-for="item in items" :key="item.id">
              <td align="center" style="width: 20%" class="col-font">
                  {{ previsao_recebimento_agrupado=="MM" ? C_MES_EXTENSO[item.agrupado-1]+" de "+item.ano : item.agrupado }}
              </td>
              <td align="right" style="width: 10%" class="col-font">
                {{ item.tot_entr_qtd }}
              </td>
              <td align="right" style="width: 20%" class="col-font">
                R$ {{ formatNumber(parseFloat(item.tot_entr_valor)) }}
              </td>
              <td align="right" style="width: 10%" class="col-font">
                {{ item.tot_parc_qtd }}
              </td>
              <td align="right" style="width: 20%" class="col-font">
                R$ {{ formatNumber(parseFloat(item.tot_parc_valor)) }}
              </td>
              <td align="right" style="width: 25%" class="col-font">
                R$ {{ formatNumber(parseFloat(item.tot_geral)) }}
              </td>
            </tr>
          </tbody>

          <tbody v-if="isMobile" class="tbody-mobile w-100">
              <tr v-for="item in items" :key="item.id" class="w-100" style="height: 200px;">
                <td class="w-100 d-flex justify-space-between flex-column mb-3 pt-2 pb-4" style="background-color:#FFFFFF; height: 200px;">
                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold body-2 ml-3"
                    >Mês/Ano</span
                    >
                    <span class="mr-3 body-2">{{ item.agrupado }}</span>
                  </div>

                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold body-2 ml-3"
                    >Entradas</span
                    >
                    <span class="mr-3 body-2">
                    {{ item.tot_parc_qtd }}</span
                    >
                  </div>

                  <div class="d-flex justify-space-between">
                      <span class="font-weight-bold body-2 ml-3"
                        >Valor Entradas</span
                        >
                      <span class="mr-3 body-2">
                      R$
                      {{
                      formatNumber(parseFloat(item.tot_entr_valor))
                      }}</span
                      >
                  </div>

                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold body-2 ml-3"
                    >Parcelas</span
                  >
                    <span class="mr-3"> {{ item.tot_parc_qtd }}</span>
                  </div>

                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold body-2 ml-3">
                    Valor Parcelas</span
                    >
                    <span class="mr-3 body-2">
                    R$
                    {{
                      formatNumber(parseFloat(item.tot_parc_valor))
                    }}</span
                    >
                  </div>

                  <div class="d-flex justify-space-between">
                    <span class="font-weight-bold body-2 ml-3">
                    Total Geral</span
                    >
                    <span class="mr-3">
                    R$
                    {{ formatNumber(parseFloat(item.tot_geral)) }}</span
                    >
                  </div>
                </td>
              </tr>
            </tbody>
        </template>
      </v-data-table>

        <!-- RODAPE TABELA -->
        <div class="mt-4 ml-4 mr-9 container-footer-total">
          <table class="w-100">
            <tbody>
              <tr class="text sticky-table-footer-desktop">
                <td align="center" style="width: 20%; background-color:#FFFFFF"></td>
                <td align="right" style="width: 10%; background-color:#FFFFFF"><span class="col-font-sumario">
                  {{ previsao_recebimento_entr_quant }}</span>
                </td>
                <td align="right" style="width: 20%; background-color:#FFFFFF"><span class="col-font-sumario">
                  R$ {{ formatNumber(previsao_recebimento_entr_total) }}</span>
                </td>
                <td align="right" style="width: 10%; background-color:#FFFFFF"><span class="col-font-sumario">
                  {{ previsao_recebimento_parc_quant }}</span>
                </td>
                <td align="right" style="width: 20%; background-color:#FFFFFF"><span class="col-font-sumario">
                  R$ {{ formatNumber(previsao_recebimento_parc_total) }}</span>
                </td>
                <td align="right" style="width: 25%; background-color:#FFFFFF"><span class="col-font-sumario">
                  R$ {{ formatNumber(previsao_recebimento_tot_geral) }}</span>
                </td>
              </tr>

              <tr class="text sticky-table-footer-mobile w-100">
                <td class="d-flex justify-space-between w-100">
                  <span>Entradas</span>
                  {{ previsao_recebimento_entr_quant }}
                </td>
                <td class="d-flex justify-space-between w-100">
                  <span>Valor Entradas</span>
                  R$ {{ formatNumber(previsao_recebimento_entr_total) }}
                </td>
                <td class="d-flex justify-space-between w-100">
                  <span>Parcelas</span>
                  {{ previsao_recebimento_parc_quant }}
                </td>
                <td class="d-flex justify-space-between w-100">
                  <span>Valor Parcelas</span>
                  R$ {{ formatNumber(previsao_recebimento_parc_total) }}
                </td>
                <td class="d-flex justify-space-between w-100">
                  <span>Total Geral</span>
                  R$ {{ formatNumber(previsao_recebimento_tot_geral) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

    </v-card>
  </v-col>
</template>

<script>
import moment from "moment";
import { API } from "../../../../services/API";
import { formatNumber } from "../../../../services/funcoes";
import { C_MES_EXTENSO, COR_PRINCIPAL } from '../../../../services/constantes'

export default {
  name:"PainelCardPrevisaoRecebimento",

  props : { empreendimento_selecionado  : {
                                            type    : Number,
                                            default : 0
                                          },
            gridheight                  : {
                                            type    : Number,
                                            default : 0
                                          },

  },

  data() {
    return {
      mounted               : false,
      enabled               : null,
      slots                 : ["footer"],

      COR_PRINCIPAL : COR_PRINCIPAL,

      formatNumber: formatNumber,

      C_MES_EXTENSO         : C_MES_EXTENSO,
      items_agrupado        : [ {id:"MM"  , nome:"Mensal"},
                                {id:"YYYY", nome:"Anual"}],
      previsao_recebimento                : [],
      previsao_recebimento_entr_quant     : 0,
      previsao_recebimento_entr_total     : 0,
      previsao_recebimento_parc_quant     : 0,
      previsao_recebimento_parc_total     : 0,
      previsao_recebimento_tot_geral      : 0,
      data_previsao_recebimento_inicial   : null,
      data_previsao_recebimento_final     : null,
      previsao_recebimento_agrupado       : "MM",
      loading                             : true,

      active: [],

      headers: [
        {
          text: "Mês/Ano",
          value: "mes_ano",
          align: "center",
        },
        {
          text: "Entradas",
          value: "tot_entr_qtd",
          align: "right",
        },
        {
          text: "Valor Entradas",
          value: "tot_entr_valor",
          align: "right",
        },
        {
          text: "Parcelas",
          value: "tot_parc_qtd",
          align: "right",
        },
        {
          text: "Valor Parcelas",
          value: "tot_parc_valor",
          align: "right",
        },
        {
          text: "Total Geral",
          value: "tot_geral",
          align: "right",
        },
      ],

    };
  },

  watch: {
    // Mudou o Empreendimento -----------------------------------
    empreendimento_selecionado() {
      // console.log('watch previsão Recebimento ----- ', this.empreendimento_selecionado)
      this.painel_previsao_recebimento(
        this.empreendimento_selecionado,
        this.data_previsao_recebimento_inicial,
        this.data_previsao_recebimento_final,
        this.data_previsao_recebimento_agrupado
      );
    },

    // Mudou Previsao de Recebimento -----------------------------------
    data_previsao_recebimento_inicial() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_previsao_recebimento(
            this.empreendimento_selecionado,
            this.data_previsao_recebimento_inicial,
            this.data_previsao_recebimento_final,
            this.previsao_recebimento_agrupado
          );
        }, 1000);
      }
    },
    data_previsao_recebimento_final() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_previsao_recebimento(
            this.empreendimento_selecionado,
            this.data_previsao_recebimento_inicial,
            this.data_previsao_recebimento_final,
            this.previsao_recebimento_agrupado
        )
        }, 1000);
      }  
    },
    previsao_recebimento_agrupado() {
      if (this.mounted) {
        clearTimeout(this._searchTimerId);
        this._searchTimerId = setTimeout(async () => {
          this.painel_previsao_recebimento(
            this.empreendimento_selecionado,
            this.data_previsao_recebimento_inicial,
            this.data_previsao_recebimento_final,
            this.previsao_recebimento_agrupado
        )
        }, 50);
      }
    },

  },

  async mounted() {
   
  },

  methods: {
    isEnabled(slot) {
      return this.enabled === slot;
    },

    async painel_previsao_recebimento(p_codEmpreendimento, p_data_inicial, p_data_final, p_previsao_recebimento_agrupado) {
      this.loading = true;
      if (!p_previsao_recebimento_agrupado) 
        p_previsao_recebimento_agrupado = "MM";
      const datas = { cod_empreendimento: p_codEmpreendimento,
                      data_inicial      : moment(p_data_inicial, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                      data_final        : moment(p_data_final, 'YYYY-MM-DD').format('DD/MM/YYYY'),
                      agrupado          : p_previsao_recebimento_agrupado };
      const resp = await API.get(`painel_previsao_recebimento`, {
        params: datas,
      });
      if (resp) {
        this.previsao_recebimento = resp.data.result.rows;
        this.mounted = false;
        if (!this.data_previsao_recebimento_inicial)
          this.data_previsao_recebimento_inicial = moment(resp.data.result.data_inicial, 'DD/MM/YYYY').format('YYYY-MM-DD');
        if (!this.data_previsao_recebimento_final)
          this.data_previsao_recebimento_final   = moment(resp.data.result.data_final, 'DD/MM/YYYY').format('YYYY-MM-DD');
        this.previsao_recebimento_entr_quant = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_entr_qtd), 0);
        this.previsao_recebimento_entr_total = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_entr_valor), 0);
        this.previsao_recebimento_parc_quant = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_parc_qtd), 0);
        this.previsao_recebimento_parc_total = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_parc_valor), 0);
        this.previsao_recebimento_tot_geral  = this.previsao_recebimento.reduce((total, item) => total + parseFloat(item.tot_geral), 0);
        this.loading = false;
        this.$nextTick(async () => {
          this.mounted = true;
        })
      }
    },

  },

  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },
  },

};
</script>


<style scoped>
*,
a {
  text-decoration: none;
}

.card-price {
  background: #274abb;
  color: #fff !important;
}

.card-price span {
  color: #fff !important;
  opacity: 0.8;
  font-size: 20px;
}

.card-location {
  background: #4bc0c0;
  color: #fff !important;
}

.card-location span {
  opacity: 0.8;
  font-size: 20px;
}

.card-status {
  background: #9966ff;
  color: #fff !important;
}

.card-status span {
  opacity: 0.8;
  font-size: 20px;
}

.card-type {
  background: #519e8a;
  color: #fff !important;
}

.card-type span {
  opacity: 0.8;
  font-size: 20px;
}

.card-price .body-2,
.card-location .body-2,
.card-status .body-2,
.card-type .body-2 {
  color: #fff !important;
  opacity: 0.9;
}

.card-type span {
  background: #389f83;
}

.body-2 {
  display: flex;
  justify-content: space-between;
  font-size: 13px !important;
}

.body-3 {
  font-size: 16px !important;
  color: #fff;
}

.icon-dashboard {
  font-size: 2.2rem;
  color: #fff;
  opacity: 0.6;
}

.card-price span,
.card-location span,
.card-status span,
.card-type span {
  margin-top: -12px;
  padding-left: 16px;
}

.card-container {
  overflow-x: auto;
}

@media (max-width: 599px) {
  .card-container {
    margin-top: 24px;
  }
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.sticky-table-footer-mobile {
  display: none;
}

@media (max-width: 599px) {
  .sticky-table-footer-desktop {
    display: none;
  }

  .container-footer-total {
    padding-right: 0px!important;
    padding-left: 0px!important;
  }

  .sticky-table-footer-mobile {
    display: block;
  }

  .sticky-table-footer-mobile td {
    margin-top: 10px;
    padding: 4px;
    font-weight: bold;
    position: sticky;
    bottom: 0;
    background-color: #f8f8f8;
    color: #3268a3;
    font-size: 14px;
  }
}

.main-title {
  background: #0548a3;
}

.text-white {
  font-size: 20px;
  color: #FFFFFF !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/
@media (max-width: 599px) {
  .container-table {
    padding: 0px 8px !important;
  }
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

.search-input {
  margin: 0.8rem 50% 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
}

@media (max-width: 599px) {
  .search-input {
    width: 200px;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    min-width: 100%;
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-content {
  border-radius: 20px 20px !important;
  background: #fff !important;
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 4px !important;
    padding-left: 2px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

@media (max-width: 599px) {
  .container-content {
    padding: 16px !important;
  }
}

.container-table {
  max-width: 100% !important;
  border-radius: 16px 16px 0px 0px;
  background: #f2f6f7;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.height {
  height: 513px !important;
}

@media (min-width: 1441px) {
  .height {
    height: initial !important;
  }
}

@media (max-width: 768px) {
  .height {
    height: initial !important;
  }
}

.row-date {
  padding-top: 18px !important;
}

@media (max-width: 599px) {
  .row-date {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .td-mobile {
    display: flex;
  }

  .label-date-solo {
    display: none;
  }
}

.label-date {
  font-size: 14px;
  font-weight: 500;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}

@media(min-width: 1441px) {
  .col-painel .min-height {
    /* height: 733px!important; */
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .col-painel,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .col-painel,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.table-mobile {
  display: none;
}

@media (max-width: 599px) {
  .table-mobile {
    display: block;
  }

  .tbody-mobile {
    padding: 12px !important;
  }

  .font-weight-bold {
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.6);
  }
}

.last-tr {
  border-bottom: 1px solid #dddcdc !important;
}

.cor_titulo_painel {
  font-size: 18px !important;
  color: #3268a3;
}

.col-font-sumario {
  font-size: 15px !important;
  color: #3268a3;
}

.col-font {
  font-size:13px !important
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250 ,250, 250);
}

.w-100 {
  width: 100%;
}

.card-size {
min-width: 150px; 
  height: 100px;
}

.date-mobile {
  margin-top: -16px!important;
  margin-bottom: 34px!important;
}

.data-input {
  font-weight: 400;
  font-size: 15px;
}

/* ////++++ */
.table{
	max-width: calc(100% - 48px);
	max-height: calc(100vh - 170px);
}
.v-data-table {
	overflow: auto;
}
.v-data-table /deep/ .v-data-table__wrapper {
	overflow: unset;
}

.vue-draggable-handle {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;
    padding: 1px 1px 1px 1px;
    background-origin: content-box;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: move;
}

</style>
