<template>
    <v-card id="PaineCardResumoVenda" class="card_container w-100 d-flex flex-row pr-4" elevation="1">
        <div v-if="!isMobile" class="vue-draggable-handle">
          <v-icon x-small color="#A0A0A0">mdi-arrow-all</v-icon>
        </div>
      
      <v-col cols="6" lg="2" md="4">

        <v-card class="card-price card-size">
          <v-card-text class="body-2" style="height: 45px"
            >Qtde Vendas
            <v-icon class="icon-dashboard">mdi-currency-usd</v-icon>
          </v-card-text>
          <table class="ml-2 mt-1" width="100%">
            <tr>
              <td>
                <div align="center" class="body-2">Vista<br>{{ resumo_financeiro.tot_qtde_vendas_avista }}</div>
              </td>
              <td>
                <div class="body-2">Prazo<br>{{ resumo_financeiro.tot_qtde_vendas_parcelado }}</div>
              </td>
              <td>
                <div class="body-2">Total<br>{{ parseFloat(resumo_financeiro.tot_qtde_vendas_avista) + parseFloat(resumo_financeiro.tot_qtde_vendas_parcelado) }}</div>
              </td>
            </tr>
          </table>
        </v-card>
      </v-col>

      <v-col cols="6" lg="2" md="4">
        <v-card class="card-type card-size">
          <v-card-text class="body-2" style="height: 45px"
            >Total (Á Vista)<v-icon class="icon-dashboard">mdi-currency-usd</v-icon>
          </v-card-text
          ><div class="mt-3"><span class="body-3">R$ {{formatNumber(resumo_financeiro.tot_avista)}}</span></div>
        </v-card>
      </v-col>

      <v-col cols="6" lg="2" md="4">
        <v-card class="card-location card-size">
          <v-card-text class="body-2" style="height: 45px"
            >Total (Entradas)<v-icon class="icon-dashboard"
              >mdi-currency-usd</v-icon
            ></v-card-text
          ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_entrada) }}</span></div>
          </v-card>
      </v-col>

      <v-col cols="6" lg="2" md="4">
        <v-card class="card-status card-size">
          <v-card-text class="body-2" style="height: 45px"
            >Total (Parcelas)<v-icon class="icon-dashboard"
              >mdi-currency-usd</v-icon
            ></v-card-text
          ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_parcela) }}</span></div>
          </v-card>
      </v-col>

      <v-col cols="6" lg="2" md="4">
        <v-card class="card-type card-size">
          <v-card-text class="body-2" style="height: 45px"
            >Total (Juros)<v-icon class="icon-dashboard"
              >mdi-currency-usd</v-icon
            ></v-card-text
          ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_juros_financiamento) }}</span></div>
        </v-card>
      </v-col>

      <v-col cols="6" lg="2" md="4">
        <v-card class="card-price card-size">
          <v-card-text class="body-2" style="height: 45px"
            >Total (Contrato)
            <v-icon class="icon-dashboard"
              >mdi-currency-usd</v-icon
            > </v-card-text
          ><div class="mt-3"><span class="body-3">R$ {{ formatNumber(resumo_financeiro.tot_contrato) }}</span></div>
        </v-card>
      </v-col>

    </v-card>
</template>

<script>
import moment from "moment";
import { API } from "../../../../services/API";
import { formatNumber } from "../../../../services/funcoes";
import { C_MES_EXTENSO, COR_PRINCIPAL } from '../../../../services/constantes'


export default {
  name:"PaineCardResumoVenda",

  props : {empreendimento_selecionado: {
                                        type    : Number,
                                        default : 0
                                       }
  },

  data() {
    return {
      mounted               : false,
      enabled               : null,
      slots                 : ["footer"],
      C_MES_EXTENSO         : C_MES_EXTENSO,
      items_agrupado        : [ {id:"MM"  , nome:"Mensal"},
                                {id:"YYYY", nome:"Anual"}],

      COR_PRINCIPAL : COR_PRINCIPAL,

      formatNumber: formatNumber,

      intervalo: 1,
      periodo_inicial: null,
      periodo_final: null,

      resumo_financeiro   : {},
      loading             : true,

      active: [],

      headers: [
        {
          text: "Mês/Ano",
          value: "mes_ano",
          align: "center",
        },
        {
          text: "Entradas",
          value: "tot_vl_entr",
          align: "right",
        },
        {
          text: "Parcelas",
          value: "tot_vl_parc",
          align: "right",
        },
        {
          text: "Total",
          value: "total_geral",
          align: "right",
        },
      ],

    };
  },

  watch: {
    // Mudou o Empreendimento -----------------------------------
    empreendimento_selecionado() {
      // console.log('watch Inadimplencia----- ', this.empreendimento_selecionado)
      this.painel_proprietario(
        this.empreendimento_selecionado,
        this.intervalo
      );
    },


  },

  methods: {

    async painel_proprietario(p_codEmpreendimento, p_periodo, p_data_inicial, p_data_final) {
      if (p_periodo == 0) {
        // HOJE
        p_data_inicial = moment().format("DD/MM/YYYY");
        p_data_final = moment().format("DD/MM/YYYY");
      } else if (p_periodo == 1) {
        // SEMANA
        p_data_inicial = moment().day(0).format("DD/MM/YYYY");
        p_data_final = moment().day(6).format("DD/MM/YYYY");
      } else if (p_periodo == 2) {
        // MES
        p_data_inicial = moment().startOf("month").format("DD/MM/YYYY");
        p_data_final = moment().endOf("month").format("DD/MM/YYYY");
      } else if (p_periodo == 3) {
        // ANO
        p_data_inicial = moment().startOf("year").format("DD/MM/YYYY");
        p_data_final = moment().endOf("year").format("DD/MM/YYYY");
      } else if (p_periodo == 4) {
        // PERIODO
        p_data_inicial = moment(this.periodo_inicial).format("DD/MM/YYYY");
        if (!p_data_inicial || p_data_inicial == "Invalid date") {
          p_data_inicial = "01/01/1900";
        }
        p_data_final = moment(this.periodo_final).format("DD/MM/YYYY");
        if (!p_data_final || p_data_final == "Invalid date")
          p_data_final = "01/01/2200";
      }

      const datas = { cod_empreendimento: p_codEmpreendimento, data_inicial: p_data_inicial, data_final: p_data_final };
      const resp = await API.get(`painel_proprietario`, {
        params: datas,
      });

      // Montando Gráfico da Situação --------------------------------------------------------
      if (resp && resp.data.result.resumo_financeiro) {
        this.resumo_financeiro = resp.data.result.resumo_financeiro[0]
      }
    }

  },
  
  computed:{
    isMobile(){
      return this.$vuetify.breakpoint.name==='xs'
    },
  },

};
</script>


<style scoped>
*,
a {
  text-decoration: none;
}

.card-price {
  background: #274abb;
  color: #fff !important;
}

.card-price span {
  color: #fff !important;
  opacity: 0.8;
  font-size: 20px;
}

.card-location {
  background: #4bc0c0;
  color: #fff !important;
}

.card-location span {
  opacity: 0.8;
  font-size: 20px;
}

.card-status {
  background: #9966ff;
  color: #fff !important;
}

.card-status span {
  opacity: 0.8;
  font-size: 20px;
}

.card-type {
  background: #519e8a;
  color: #fff !important;
}

.card-type span {
  opacity: 0.8;
  font-size: 20px;
}

.card-price .body-2,
.card-location .body-2,
.card-status .body-2,
.card-type .body-2 {
  color: #fff !important;
  opacity: 0.9;
}

.card-type span {
  /* background: #389f83; */
}

.body-2 {
  display: flex;
  justify-content: space-between;
  font-size: 13px !important;
}

.body-3 {
  font-size: 16px !important;
  color: #fff;
}

.icon-dashboard {
  font-size: 2.2rem;
  color: #fff;
  opacity: 0.6;
}

.card-price span,
.card-location span,
.card-status span,
.card-type span {
  margin-top: -12px;
  padding-left: 16px;
}

.card_container {
  overflow-x: auto;
}

@media (max-width: 599px) {
  .card_container {
    overflow-x: auto;
    margin-top: -1px;
  }
}

.sticky-table-footer-desktop td {
  font-weight: bold;
  position: sticky;
  bottom: 0;
  background-color: #f8f8f8;
  color: rgba(0, 0, 0, 0.6);
}

.sticky-table-footer-mobile {
  display: none;
}

@media (max-width: 599px) {
  .sticky-table-footer-desktop {
    display: none;
  }

  .container-footer-total {
    padding-right: 0px!important;
    padding-left: 0px!important;
  }

  .sticky-table-footer-mobile {
    display: block;
  }

  .sticky-table-footer-mobile td {
    margin-top: 10px;
    padding: 4px;
    font-weight: bold;
    position: sticky;
    bottom: 0;
    background-color: #f8f8f8;
    color: #3268a3;
    font-size: 14px;
  }
}

.main-title {
  background: #0548a3;
}

.text-white {
  font-size: 20px;
  color: #FFFFFF !important;
}

table tr {
  width: 100%;
}

.table-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  background: #fff;
}

.table-row:hover {
  background: #f6f6f6 !important;
}

/*-----------------------------------*/
/*------ RESPONSIVE GENERAL ------*/
/*-----------------------------------*/
@media (max-width: 599px) {
  .container-table {
    padding: 0px 8px !important;
  }
}

.v-toolbar__content {
  padding-left: 0px !important;
}

.v-input__control {
  background: #2a63b1 !important;
}

.search-input {
  margin: 0.8rem 50% 1.2rem 0;
  height: 35px !important;
}

@media (max-width: 768px) {
  .search-input {
    margin: 0.8rem 10% 1.2rem 0;
  }
}

@media (max-width: 599px) {
  .search-input {
    width: 200px;
  }

  .search-input-longo {
    margin: 0.8rem 10% 1.2rem 0;
    height: 35px !important;
    min-width: 100%;
  }
}

.v-input__control,
.v-input__slot,
.v-select__slot {
  height: 35px !important;
}

.v-label {
  margin-right: 0px !important;
}

.icon-action {
  font-size: 1.3rem !important;
}

@media (max-width: 768px) {
  .btn-icon {
    margin-right: 8px !important;
  }
}

.container-content {
  border-radius: 20px 20px !important;
  background: #fff !important;
}

@media (max-width: 768px) {
  .main-title {
    padding-right: 4px !important;
    padding-left: 2px !important;
  }

  .container-content {
    max-width: 740px !important;
  }
}

@media (max-width: 599px) {
  .container-content {
    padding: 16px !important;
  }
}

.container-table {
  max-width: 100% !important;
  border-radius: 16px 16px 0px 0px;
  background: #f2f6f7;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-size: 1.5rem !important;
}

.height {
  height: 513px !important;
}

@media (min-width: 1441px) {
  .height {
    height: initial !important;
  }
}

@media (max-width: 768px) {
  .height {
    height: initial !important;
  }
}

.row-date {
  padding-top: 18px !important;
}

@media (max-width: 599px) {
  .row-date {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }

  .td-mobile {
    display: flex;
  }

  .label-date-solo {
    display: none;
  }
}

.label-date {
  font-size: 14px;
  font-weight: 500;
}

.v-toolbar__content,
.v-toolbar__extension {
  padding: 4px 0px !important;
}

@media(min-width: 1441px) {
  .col-inadimplencia .min-height {
    /* height: 733px!important; */
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 1024px) {
  .col-inadimplencia,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

@media (max-width: 768px) {
  .col-inadimplencia,
  .col-situacao {
    padding-right: 0px;
    padding-left: 0px;
  }
}

.table-mobile {
  display: none;
}

@media (max-width: 599px) {
  .table-mobile {
    display: block;
  }

  .tbody-mobile {
    padding: 12px !important;
  }

  .font-weight-bold {
    font-weight: 600!important;
    color: rgba(0, 0, 0, 0.6);
  }
}

.last-tr {
  border-bottom: 1px solid #dddcdc !important;
}

.cor_titulo_painel {
  font-size: 18px !important;
  color: #3268a3;
}

.col-font-sumario {
  font-size: 15px !important;
  color: #3268a3;
}

.col-font {
  font-size:13px !important
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250 ,250, 250);
}

.w-100 {
  width: 100%;
}

.card-size {
  min-width: 150px; 
  height: 100px;
}

.date-mobile {
  margin-top: -16px!important;
  margin-bottom: 34px!important;
}

.data-input {
  font-weight: 400;
  font-size: 15px;
}

.vue-draggable-handle {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 0;
    right: 0;
    padding: 1px 1px 1px 1px;
    background-origin: content-box;
    background-color: transparent;
    box-sizing: border-box;
    border-radius: 5px;
    cursor: move;
}

</style>


